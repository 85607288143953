<template>
  <div class="about">

    <link rel="stylesheet" href="https://demos.creative-tim.com/notus-js/assets/styles/tailwind.css">
<link rel="stylesheet" href="https://demos.creative-tim.com/notus-js/assets/vendor/@fortawesome/fontawesome-free/css/all.min.css">

<div v-if="!logged"  class="w-full flex mt-24 justify-center">

  <form class="flex bg-gray-100 py-10 px-5 rounded flex-col max-w-xl justify-center" action="">
    <h2 class="text-center text-xl mb-5 font-bold">Identifique-se</h2>
    <p v-if="error" class="mt-3 mb-3 bg-red-300 rounded py-1 text-white">Dados inválidos</p>
    <label for="">Username</label>
    <input v-model="username" type="text">
    <label class="mt-5" for="">Senha</label>
    <input v-model="password"  type="password">
    <input type="button" class="mt-5 bg-gray-300 py-3 hover:bg-gray-400 cursor-pointer" value="Entrar" @click="login">
    
</form>

</div>

<h2 v-if="logged" class="text-3xl mt-20">Olá Lah <small>(<a href="" @click.prevent="logout" class="cursor-pointer">Sair</a>)</small> </h2>

<section v-if="logged" class="py-1 bg-blueGray-50">
<div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4 mx-auto mt-24">
  <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded ">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">Dados Captados</h3>
        </div>
        <!-- <div class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
          <button class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">See all</button>
        </div> -->
      </div>
    </div>

    <div class="block w-full overflow-x-auto">
      <table class="items-center bg-transparent w-full border-collapse uppercase">
        <thead>
          <tr>
            <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Nome
                        </th>
           <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Telefone
                        </th>
          <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Endereço
                        </th>
          <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Data Nascimento
                        </th>
          <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          N. Sapato
                        </th>
          <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Ações
                        </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, i) in data" :key="i">
            <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left ">
              {{item.name}} <br>
              {{item.email}}
            </th>
            <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
              {{item.phone}}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
              {{item.address}}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
              {{formatData(item.birthday, "YYYY-MM-DD", "DD/MM/YYYY")}}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
              {{item.shoes_size}}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
              <a href="" class="cursor-pointer bg-red-500 text-white px-3 py-1 text-xs hover:bg-red-600" @click.prevent="deleteItem(item.id)"> Apagar</a>
            </td>
          </tr>

        </tbody>

      </table>
    </div>
  </div>
</div>
<footer class="relative pt-8 pb-6 mt-16">
  <div class="container mx-auto px-4">
    <div class="flex flex-wrap items-center md:justify-between justify-center">
      <div class="w-full md:w-6/12 px-4 mx-auto text-center">
        <div class="text-sm text-blueGray-500 font-semibold py-1">

        </div>
      </div>
    </div>
  </div>
</footer>
</section>
  </div>
</template>

<script>
import {getAll, addOne, delOne} from "../firebase"
import moment from "moment"


export default {
  data(){
    return {
      data:null,
      username:"",
      password:"",
      logged:false,
      error:false
    }
  },
  methods:{
    login(){
      if(this.username=="lah" && this.password=="123456"){
        localStorage.setItem("logged", "lkdf2df1df5")
        this.logged=true
      }else{
        this.error = true
        setTimeout(()=>{
          this.error = false
        },3000)
      }
    },
    logout(){
      localStorage.removeItem("logged")
      this.logged=false
    },
    formatData(date, formatOrigin, formatFinally){
      return moment(date, formatOrigin).format(formatFinally)
    },
   deleteItem(id){
      delOne("question", id).then(r=>{
        getAll("question").then(r2=>this.data=r2)
      })

      
    }
  },
  async mounted(){
    if(localStorage.getItem("logged")){
      this.logged=true
    }
        
    this.data = await getAll("question");
  }
}
</script>
